import React from 'react'
import { Link } from 'gatsby'
import { Parallax } from 'react-parallax'
import ReactWOW from 'react-wow';

const Banner = (props) => {
    return(
        <section className={`industries_banner ${props.bannerClassName}`}
        style={{backgroundImage: `url(${props.bannerImage})`}}>

                        <ReactWOW animation="zoomIn" delay="0.2s"><div className="industries_round_shape"></div></ReactWOW>

                         <header className="industries_header">
                         
                            <ReactWOW animation="fadeInUp" delay="0.6s"><h6>{props.subtitle}</h6></ReactWOW>
                            <ReactWOW animation="fadeInUp" delay="0.8s"><h2 style={{marginBottom: '0px'}}>{props.title}</h2></ReactWOW>
                            <ReactWOW animation="fadeInUp" delay="1.0s"><p >{props.bannerText}</p></ReactWOW>
                            <div className='industries_btn_contain'>
                            {props.buttonLeft ? (<ReactWOW animation="fadeInLeft" delay="0.9s"><Link className="general_banner_btn general_banner_btn_two btn_hover" style={{marginRight: '5px'}} to={props.buttonLeft.link}>{props.buttonLeft.text}</Link></ReactWOW>) : null}
                           {props.buttonRight ? (<ReactWOW animation="fadeInRight" delay="0.9s"><Link className="general_banner_btn general_banner_btn_two btn_hover" to={props.buttonRight.link}>{props.buttonRight.text}</Link></ReactWOW>): null} 
                           </div>
                           
                        </header>
        </section>
    )
}

export default Banner

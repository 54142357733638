import React from "react"
import SEO from "../../../components/common/SEO"
import Layout from "../../../components/layouts"
import Banner from "../../../components/industries/Banner"
import Promo from "../../../components/common/Promo"
import ReactWOW from "react-wow"

const seo = {
  title: "Workhub | Industries | All-in-one EHS Software for Construction ",
  description: "",
  image: "",
  // pathname,
  // article
}

const bannerProps = {
  title: "Construction",
  subtitle: "Industries",

  bannerImage: "/industries/construction/construction_banner.png",
  bannerClassName: "construction_banner",
  bannerText:
    "Workhub provides a health and safety compliance software for the construction industry to elevate their safety program and proactively mitigate risks.",

  buttonLeft: {
    text: "Features",
    link: "/features/",
  },

  buttonRight: {
    text: "Pricing",
    link: "/pricing/",
  },
}

const IntroParas = [
  {
    para:
      "We can help you manage a fluctuating workforce, high-risk environment, and constantly evolving jobsite.",
  },
]

const industriesPromo = {
  title: "What is Workhub?",
  text:
    "We want to help people see the value in day-to-day safety operations. That's why we created a tool that is easy to use and makes safety compliance interactive and engaging.",
}

function index() {
  return (
    <Layout>
      <SEO {...seo} />
      <Banner {...bannerProps} />

      {/* INTRODUCTION PARAHRAPH */}

      <section className="industries_intro_container">
        <div className="industries_intro">
          <div className="industries_intro_text">
            {/* <h3>Introduction</h3> */}
            {IntroParas.map(item => (
              <h2>{item.para}</h2>
            ))}

            <ul className="analytices_list" style={{paddingTop: '1rem'}}>
              <li>Make contractor onboarding easy by hosting your orientations in a convenient video.</li>
              <li>Track important safety compliance activities such as training and certificates.</li>
              <li>Mitigate risks through incident management, inspections, and behavior observations.</li>
              <li>Ensure your workers are aligned with your organization and day-to-day duties through policies and operating procedures.</li>
            </ul>
          </div>

          {/* INTRODUCTION CARTOON/GRAPHIC */}

          <div className="industries_intro_img">
            <ReactWOW animation="fadeInRight" delay="0.4s">
              <img src="/industries/construction/construction-cartoon.png" />
            </ReactWOW>
          </div>
        </div>
      </section>

      {/* 'WHAT IS WORKHUB?' BANNER */}

      {/* <Promo promo={industriesPromo} /> */}

      {/* FEATURE HIGHLIGHT CARDS */}

      {/* <section className="industries_card_container">
        <div className="i_card_header">
          <h3>Feature Spotlight</h3>
          <p style={{ fontSize: "18px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            nibh velit, interdum nec euismod sed, posuere quis nisi. Aenean
            facilisis sapien purus.
          </p>
        </div>

        <div className="i_card_container">
          <div className="industry_row">
            <div class="i_card">
              <div
                class="i_face i_face1"
                style={{
                  backgroundImage: `url('/industries/construction/one.jpg')`,
                }}
              >
                <div class="i_content">
                  <h3>Worker Management</h3>
                </div>
              </div>
              <div class="i_face i_face2">
                <div class="i_content">
                  <p style={{ fontSize: "14px", color: "white" }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quas cum cumque minus iste veritatis provident at.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="i_card">
              <div
                class="i_face i_face1"
                style={{
                  backgroundImage: `url('/industries/construction/two.jpg')`,
                }}
              >
                <div class="i_content">
                  <h3>Safety Meetings</h3>
                </div>
              </div>
              <div class="i_face i_face2">
                <div class="i_content">
                  <p style={{ fontSize: "14px", color: "white" }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quas cum cumque minus iste veritatis provident at.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="i_card">
              <div
                class="i_face i_face1"
                style={{
                  backgroundImage: `url('/industries/construction/three.jpg')`,
                }}
              >
                <div class="i_content">
                  <h3>Contractor Orientations</h3>
                </div>
              </div>
              <div class="i_face i_face2">
                <div class="i_content">
                  <p style={{ fontSize: "14px", color: "white" }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quas cum cumque minus iste veritatis provident at.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* CASE STUDIES */}

      {/* <section className='industries_cs_container'>


        <div className='industries_case_study'>
       <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nibh velit, interdum nec euismod sed, posuere quis nisi. Aenean facilisis sapien purus, ac congue urna aliquet vel. Aenean varius efficitur ornare. Maecenas dignissim sodales nisi, vitae mollis sapien blandit non. Vivamus id placerat ligula. </p>
       <a href="/">Learn more ></a>
        </div>


      </section> */}


    </Layout>
  )
}

export default index
